import { IModel } from '../base-model';
import { BuildingContacts } from './building-contact';
import { BuildingSchedules } from './building-schedule';

export interface IBuilding extends IModel {
   name?: string;
   description?: string;
   address?: string;
   coordinates?: string;
   type?: '1' | '2';
   contacts?: BuildingContacts;
   contactsCount?: number;
   schedules?: BuildingSchedules;
   scheduleCount?: number;
   stept?: string;
   rekognition?: boolean;
}

export type Buildings = IBuilding[];

export type BuildingTypeLabel = 'enterprise';

/**
 * 1. Enterprise
 */

export type BuildingType = '1';

export const BuildingTypes = [{
   text: 'pages.building_detail.types.enterprise',
   value: '1'
}, {
   text: 'pages.building_detail.types.residential',
   value: '2'
}]
