import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ApiResponse } from '../../../shared/models/api/response/api';
import { ParkingLotApiResponse, ParkingLotCreateApiResponse, ParkingLotImagesApiResponse, ParkingLotsApiResponse, ParkingLotUpdateApiResponse, ResidentsLotsApiResponse, ResidentLotApiResponse, ResidentLotCreateResponse, ResidentLotUpdateResponse, CondominiumCreateResponse, CondominiumUpdateResponse, CondominiumApiResponse, CondominiumsApiResponse } from '../../../shared/models/api/response/parking-lots';
import { InterceptorOptions } from '../../../shared/models/application/interceptor-options';
import { IParkingLot, ParkingLots, ParkingLotTypeLabel } from '../../../shared/models/application/parking-lot/parking-lot';
import { ParkingLotImages } from '../../../shared/models/application/parking-lot/parking-lot-image';
import { ResidentsLots, IParkingLotResidentS, IParkingLotResidentR } from 'src/app/shared/models/application/parking-lot/parking-lot-residents';
import { Condominiums, ICondominium } from 'src/app/shared/models/application/parking-lot/parking-lot-condominium';

@Injectable({
	providedIn: 'root'
})
export class ParkingLotsService {
	private endpoint = environment.apiUrl + '/parking-lots';
	private endpointResidents = environment.apiUrl + '/residents'
	private endpointCondos = environment.apiUrl + '/condos'
	private endpointLogBook = environment.apiUrl +'/historicals/parking-lot'
	private endpointScanner = environment.apiUrl +'/historicals-scanner/parking-lot'

	constructor(
		private http: HttpClient
	) { }

	getAll(type: ParkingLotTypeLabel = 'commercial', idSec?: number): Observable<ParkingLots> {
		return this.http.get<ParkingLotsApiResponse>(`${this.endpoint}/person?type=${type}&idSec=${idSec}`)
			.pipe(
				map(res => res.data)
			);
	}

	getById(id: string): Observable<IParkingLot> {
		return this.http.get<ParkingLotApiResponse>(`${this.endpoint}/${id}`)
			.pipe(
				map(res => res.data)
			);
	}

	create(data: IParkingLot): Observable<ParkingLotCreateApiResponse> {
		return this.http.post<ParkingLotCreateApiResponse>(`${this.endpoint}`, data)
			.pipe(
				map(res => {
					delete res.data.images;
					return res;
				})
			);
	}

	update(id: string, data: IParkingLot): Observable<ParkingLotUpdateApiResponse> {
		return this.http.put<ParkingLotUpdateApiResponse>(`${this.endpoint}/${id}`, data)
			.pipe(
				map(res => {
					delete res.data.images;
					return res;
				})
			);
	}

	delete(id: string): Observable<ApiResponse<undefined>> {
		return this.http.delete<ApiResponse<undefined>>(`${this.endpoint}/${id}`);
	}

	uploadImages(id: string, images: File[]): Observable<ParkingLotImagesApiResponse> {
		const formData = new FormData();

		images.forEach(img => {
			formData.append('images', img, img.name);
		});

		return this.http.post<ParkingLotImagesApiResponse>(`${this.endpoint}/${id}`, formData);
	}

	/**
	 * Call api to delete an image
	 * @param id Parking lot id
	 * @param imageId Image id
	 */
	deleteImage(id: string, imageId: string, showToastError: boolean = true): Observable<ApiResponse<undefined>> {
		return this.http.delete<ApiResponse<undefined>>(`${this.endpoint}/${id}/${imageId}`, {
			headers: {
				meta: JSON.stringify({ showToastError } as InterceptorOptions)
			}
		});
	}

	/**
	 * Delete multiple images
	 * @param id Parking lot id
	 * @param images Images array
	 */
	deleteImages(id: string, images: ParkingLotImages): Observable<{ code?: string, id?: number }[]> {
		const observables = images.filter(img => !!img.id).map(img =>
			this.deleteImage(id, img.id.toString(), false)
				.pipe(
					map(res => ({ code: null, id: Number(img.id) })),
					catchError((err: { code?: string }) => of({ code: err.code, id: Number(img.id) }))
				)
		);

		return forkJoin(observables);
	}

	// Residents in detail - Parking lot

	getAllResidents(id: string): Observable<ResidentsLots> {
		return this.http.get<ResidentsLotsApiResponse>(`${this.endpointResidents}/parkign/${id}`)
		.pipe(
			map(res => res.data)
		)

	}

	getByIdResident(id: number): Observable<IParkingLotResidentR> {
		return this.http.get<ResidentLotApiResponse>(`${this.endpointResidents}/${id}`)
			.pipe(
				map(res => res.data)
			);
	}

	createResident(data: IParkingLotResidentS): Observable<ResidentLotCreateResponse> {
		return this.http.post<ResidentLotCreateResponse>(`${this.endpointResidents}`, data)
			.pipe(
				map(res => {
					return res;
				})
			);
	}

	updateResident(id: number, data: IParkingLotResidentS): Observable<ResidentLotUpdateResponse> {
		return this.http.put<ResidentLotUpdateResponse>(`${this.endpointResidents}/${id}`, data)
			.pipe(
				map(res => {
					return res;
				})
			);
	}

	deleteResident(id: string): Observable<ApiResponse<undefined>> {
		return this.http.delete<ApiResponse<undefined>>(`${this.endpointResidents}/${id}`);
	}

	//  Condominiums

	getAllCondominiums(id: string): Observable<Condominiums> {
		return this.http.get<CondominiumsApiResponse>(`${this.endpointCondos}/parking/${id}`)
		.pipe(
			map(res => res.data)
		)
		
	}

	getByIdCondominium(id: number): Observable<ICondominium> {
		return this.http.get<CondominiumApiResponse>(`${this.endpointCondos}/${id}`)
			.pipe(
				map(res => res.data)
			);
	}

	createCondominium(data: ICondominium): Observable<CondominiumCreateResponse> {
		return this.http.post<CondominiumCreateResponse>(`${this.endpointCondos}`, data)
			.pipe(
				map(res => {
					return res;
				})
			);
	}

	updateCondominium(id: number, data: ICondominium): Observable<CondominiumUpdateResponse> {
		return this.http.put<CondominiumUpdateResponse>(`${this.endpointCondos}/${id}`, data)
			.pipe(
				map(res => {
					return res;
				})
			);
	}

	deleteCondominium(id: number): Observable<ApiResponse<undefined>> {
		return this.http.delete<ApiResponse<undefined>>(`${this.endpointCondos}/${id}`);
	}


	//logbook

	getLogBook(id: string, start: string, end: string){
		// YYYY-MM-DD is the format
		return this.http.get<any>(`${this.endpointLogBook}/${id}?start=${start}&end=${end}`)
	}

	getHistorical(id: string, start : string, end: string){
		return this.http.get<any>(`${this.endpointScanner}/${id}?start=${start}&end=${end}`).pipe(
		  map( res => res.data)
		)
	 }
}
