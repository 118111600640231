import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SealedService {
  private endpoint = environment.apiUrl + '/codes'

  constructor(
    private http: HttpClient
  ) { }

  create(id:number, data: any): Observable<any>{
    console.log(this.endpoint)
    return this.http.post<any>(`${this.endpoint}/${id}`, data ).pipe(
      map( res => {
        return res;
      })
    )
  }
}
