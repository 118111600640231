import { Injectable } from '@angular/core';
declare var google:any;
@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {
   map:any
   marker:any;
  geocoder = new google.maps.Geocoder()
  constructor() { 
  }
   
   public setMap(v : any) {
     this.map = new google.maps.Map(v, {
      zoom: 16,
      center:{lat:19.427378,lng:-99.1786837}
    })
   }
   goCenter({lat,lng}:any){
     console.log(lat,lng);
     
     this.map.setCenter({lat,lng});
     
    }
    generateMarker({lat,lng}:any){
      console.log(lat,lng);
    this.marker = new google.maps.Marker({
      map: this.map,
      position: {lat,lng}
    });
  }
  getPos(dir:string){
    return new Promise((resolve,reject)=>{
      this.geocoder.geocode({address: dir}, function(results, status) {
        if (status === 'OK') {
          var resultados = results[0].geometry.location
            resolve({lat:resultados.lat(),lng:resultados.lng()})
        }  else {
        	if (status === "ZERO_RESULTS") {
        		reject("No hubo resultados para la dirección ingresada.");
        	} else if (status === "OVER_QUERY_LIMIT" || status === "REQUEST_DENIED" || status === "UNKNOWN_ERROR") {
        		reject("Error general del mapa. Contacta con tu proveedor de serviciós");
        	} else if (status === "INVALID_REQUEST") {
        		reject("Dirección invalida, ingresa una dirección valida");
        	}
        }
      });
    }) 
  }
}
